.App {
  display: flex;
  background-color: #404258;
  overflow: hidden;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tl{
  padding: 0 10%;
  overflow-y:scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.tl::-webkit-scrollbar{
  display: none;
}

.logo{
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-left: -40px;
  margin-top: auto;
  width:100%
}
.logo > svg{
min-width: 57px !important;
}
.name{
  font-size: 3rem;
  font-weight: 900;
  color: #EEEFF2;
  white-space: nowrap;
}

.title{
  display:block;
  font-size: 1.5rem;
  font-weight: 800;
  color: #EEEFF2;
}

p {
  border-top: 2px dashed;
  border-color: #474E68 !important;
  margin:0; padding: 30px;
  counter-increment: section;
  position: relative;
}



p:nth-child(even):before {
  content: counter(section);
  right: 100%; 
  margin-right: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: #474E68;
  text-align:center;
  color: #EEEFF2;
  font-size: 110%;
}

p:nth-child(odd):before {
  content: counter(section);
  left: 100%; 
  margin-left: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: #474E68;
  text-align:center;
  color: #EEEFF2;
  font-size: 110%;
}



p:nth-child(even) {
  border-left: 2px dashed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: 30px; 
  padding-right: 0;
  color: #EEEFF2;
}

p:nth-child(odd) {
  border-right: 2px dashed;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-left: 30px; 
  padding-left: 0;
  color: #EEEFF2;
}

p:nth-child(2) {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}

p:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  margin-bottom: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 600px) {
  .App{
    flex-direction: column;
  }
  .tl{
    margin-right:0%;
    margin-top: 20px;
  }
  .globe-container{
    margin-bottom: -120%;
    background-color: transparent !important;

}
.name{
  font-size: 2rem;
  font-weight: 900;
  color: #EEEFF2;
  white-space: nowrap;
}
}
canvas{
  background-color: transparent !important;
}